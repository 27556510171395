<template>
  <div class="body add-yard">
    <div class="form">
      <el-form
        :model="addForm"
        :rules="rules"
        ref="addForm"
        label-width="82px"
        label-position="left"
        class="demo-form dialog-form"
      >
        <el-form-item label="IC卡号" prop="yardNo">
          <el-input type="text" v-model="addForm.yardNo"></el-input>
        </el-form-item>
        <el-form-item label="厂家" prop="makerId">
          <el-select clearable v-model.trim="addForm.makerId">
            <el-option
              v-for="item in makerIdList"
              :label="item.dictValue"
              :value="item.dictCode"
              :key="item.dictCode"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属公司" prop="companyId">
          <treeSelect
            :isNeedDefaultValue="value==null?true:false"
            :valueName="valueName"
            :value="value"
            @getValue="getCompanyId"
          ></treeSelect>
        </el-form-item>
        <el-form-item label="状态" prop="yardStatus">
          <el-select v-model="addForm.yardStatus" disabled placeholder="请选择">
            <el-option
              v-for="item in yardStatusList"
              :label="item.dictValue"
              :value="item.dictCode"
              :key="item.dictCode"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { insertYard, updateYard, queryDictsByCodes } from "@/api/lib/api";
import treeSelect from "@/components/treeSelect/companySelect.vue";

export default {
  components: {
    treeSelect
  },

  props: {
    item: {
      type: Object,
      default: () => { }
    }
  },

  data () {
    return {
      isEdit: false,
      value: null,
      valueName: null,
      addForm: {
        companyId: null,
        yardNo: "",
        makerId: "",
        yardStatus: ""
      },
      rules: {
        companyId: [{ required: true, message: "不能为空", trigger: "blur" }],
        yardNo: [{ required: true, message: "不能为空", trigger: "blur" }],
        makerId: [
          { required: true, message: "不能为空", trigger: "change" }
        ]
      },
      makerIdList: [],
      yardStatusList: []
    };
  },

  methods: {
    // 新增/修改
    onSave () {
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          this.$emit('onLoading', true)
          const data = {
            companyId: this.addForm.companyId,
            yardNo: this.addForm.yardNo,
            makerId: this.addForm.makerId,
            yardStatus: this.addForm.yardStatus
          }
          if (!this.isEdit) {
            insertYard(data).then((res) => {
              if (res.code === 1000) {
                this.$message.success('新增成功')
                this.clsoeDia(res.code === 1000)
              } else {
                this.$message.error(res.msg)
                this.$emit('onLoading', false)
              }
            })
          } else {
            data.id = this.addForm.id
            updateYard(data).then((res) => {
              if (res.code === 1000) {
                this.$message.success('修改成功')
                this.clsoeDia(res.code === 1000)
              } else {
                this.$message.error(res.msg)
                this.$emit('onLoading', false)
              }
            })
          }
        } else {
          return false
        }
      })
    },
    /** 取消 */
    clsoeDia (flag) {
      this.$emit('close', flag)
    },
    getDicts () {
      queryDictsByCodes({ parentCodes: 'SBCJ,ZKZT' }).then((res) => {
        if (res.code === 1000) {
          this.makerIdList = res.data.SBCJ
          this.yardStatusList = res.data.ZKZT
        }
      })
    },
    getCompanyId (v) {
      this.addForm.companyId = v;
    }
  },

  created () {
    this.getDicts()
  },
  mounted () {
    if (
      JSON.stringify(this.item) !== '{}' &&
      JSON.stringify(this.item) !== 'null'
    ) {
      this.value = this.item.companyId;
      this.valueName = this.item.companyName;
      this.addForm = { ...this.item }
      this.isEdit = true
    } else {
      this.isEdit = false
    }
  }
};
</script>

<style lang="scss" scoped>
.add-yard {
  @include themify() {
    .form-title {
      font-size: 14px;
      color: themed('n7');
      margin-bottom: 10px;
    }
  }
}
</style>
